import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

import api from 'services/api';

import * as actions from './actions';
import * as optionsActions from '../options/actions';

interface SiteResponse {
  data: {
    idSite: number;
    idEntidadeFinanceira: number;
    site: string;
    idPlano: number;
    plano: string;
    tecnologia: string;
    memoria: string;
    cpu: string;
    valor: string;
    valorVenda: boolean;
    idPeriodicidade: number;
  }[];
}

interface ResourcesResponse {
  data: {
    cpuPlano: string;
    cpuServivosAdicionais: string;
    cpuTotal: string;
    espacoDiscoPlanoGB: number;
    espacoDiscoServicosAdicionaisGB: number;
    espacoDiscoTotalGB: number;
    espacoDiscoUtilizadoGB: number;
    espacoDiscoPorcentualUtilizado: number;
    trafegoPlanoGB: number;
    trafegoServicosAdicionaisGB: number;
    trafegoTotalGB: number;
    trafegoUtilizadoGB: number;
    trafegoPorcentualUtilizado: number;
    trafegoEmailGB: number;
    trafegoEmailMB: number;
    siteQtd: number;
    memoriaPlano: string;
    memoriaServivosAdicionais: string;
    memoriaTotal: string;
    sitesCriados: number;
    sitesPorcentualUtilizado: number;
    planoIlimitado: boolean;
    espacoEmailTotalGB: number;
    mySqlTotalQtd: number;
    limiteUpload: number;
  };
}

interface OptionsResponse {
  data: {
    idOpcao: number;
    ativo: true;
    codigo: string;
    descricao: string;
    opcao: string;
  }[];
}

export function* loadSite({ payload }: ActionType<typeof actions.siteRequest>) {
  try {
    const { idSite } = payload;

    const siteInfoRequestCall = call(api.get, `clientes/v1/site/${idSite}`);

    const resourcesRequestCall = call(
      api.get,
      `painel/v1/site/${idSite}/recursos`,
    );

    const optionsRequestCall = call(api.get, `painel/v1/opcao`, {
      params: {
        idSite,
      },
    });

    const [siteInfoResponse, resourcesResponse, optionsResponse]: [
      AxiosResponse<SiteResponse>,
      AxiosResponse<ResourcesResponse>,
      AxiosResponse<OptionsResponse>,
    ] = yield all([
      siteInfoRequestCall,
      resourcesRequestCall,
      optionsRequestCall,
    ]);

    const {
      idEntidadeFinanceira,
      site,
      idPlano,
      plano,
      tecnologia,
      memoria,
      cpu,
      valor,
      valorVenda,
      idPeriodicidade,
    } = siteInfoResponse.data.data[0];

    const info = {
      idSite,
      idEntidadeFinanceira,
      site,
      idPlano,
      plano,
      tecnologia,
      memoria,
      cpu,
      valor,
      valorVenda,
      idPeriodicidade,
    };

    const resources = resourcesResponse.data.data;

    const options = optionsResponse.data.data.map(
      ({ idOpcao, codigo, ativo, opcao }) => ({
        idOpcao: Number(idOpcao),
        codigo: String(codigo),
        ativo,
        opcao,
      }),
    );

    yield put(optionsActions.optionsSuccess({ options }));
    yield put(actions.siteSuccess({ info, resources }));
  } catch (error) {
    yield put(actions.siteFailure());
  }
}

export default all([takeLatest('@site/SITE_REQUEST', loadSite)]);
