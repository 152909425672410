import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import SideMenuAccordionExternalLink from 'components/SideMenu/components/SideMenuAccordionExternalLink';
import { BookIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface HostnetContentOptionsProps {
  options: Option[];
}

const HostnetContentOptions: React.FC<HostnetContentOptionsProps> = ({
  options,
}) => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="10"
      text={t('components:sideMenu.content')}
      icon={<BookIcon size={15} />}
      elements={[
        options.some(option => option.codigo === 'academia.hostnet') && (
          <SideMenuAccordionLink
            key="academia.hostnet"
            text={t('components:sideMenu.hostnetAcademy')}
            to="/aplicativos/cursos"
          />
        ),
        <SideMenuAccordionExternalLink
          key="comunidade.hostnet"
          text={t('components:sideMenu.hostnetCommunity')}
          target="_blank"
          href="https://comunidade.hostnet.com.br/"
        />,
        <SideMenuAccordionExternalLink
          key="chatgpt.hostnet"
          text="ChatGPT Hostnet"
          target="_blank"
          href="https://chatgpt.com/g/g-ElWtYoWwQ-atendimento-hostnet"
        />,
        <SideMenuAccordionExternalLink
          key="ajuda.hostnet"
          text={t('components:sideMenu.help')}
          target="_blank"
          href="https://ajuda.hostnet.com.br/"
        />,
        <SideMenuAccordionExternalLink
          key="blog.hostnet"
          text="Blog"
          target="_blank"
          href="https://www.hostnet.com.br/blog/"
        />,
      ]}
    />
  );
};

export default HostnetContentOptions;
