import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { Card, Col, Row } from 'react-bootstrap';

import PageTitle from 'components/PageTitle';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import domainRegistry from 'assets/images/ico-azul-04.png';
import siteHosting from 'assets/images/ico-azul-05.png';
import shopCreation from 'assets/images/ico-azul-16.png';
import siteCreation from 'assets/images/ico-azul-07.png';
import siteMaintenance from 'assets/images/ico-azul-09.png';
import premiumSupport from 'assets/images/ico-azul-26.png';
import eadCreation from 'assets/images/ico-azul-28.png';
import professionalEmail from 'assets/images/ico-azul-30.png';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';

import useClient from 'hooks/useClient';

import { StoreState } from 'store/createStore';

import { CardTitle, Container } from './styles';

interface Category {
  key: string;
  title: string;
  imgUrl: string;
  description: string;
  learnMoreLink: string;
  buttonText: string;
  onClick: () => void;
}

interface AssineKeyResponse {
  data: {
    idChaveAssine: number;
    chave: string;
    idCliente: number;
    idSite: number;
  };
}

function openInNewTab(href: string) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href,
  }).click();
}

const Purchase: React.FC = () => {
  const { t } = useTranslation();

  const { client: clientData, loading } = useClient();

  const site = useSelector((state: StoreState) => state.site.info);
  const client = useSelector((state: StoreState) => state.client.info);

  const [generatingKey, setGeneratingKey] = useState(false);

  async function goToAssine() {
    try {
      setGeneratingKey(true);

      const assineSessionResponse = await api.post<AssineKeyResponse>(
        '/assine/v1/chave',
        {
          ...(client.contaComSite && { idSite: site.idSite }),
        },
      );

      openInNewTab(
        `https://assine2.hostnet.com.br/contratacao/site?CHAVE=${assineSessionResponse.data.data.chave}`,
      );
    } catch (error) {
      swal.fire(
        'Não foi possível concluir a ação',
        'Não foi possível redirecionar para a página de assinatura do serviço. Por favor, entre em contato com o suporte.',
      );
    } finally {
      setGeneratingKey(false);
    }
  }

  const categories: Category[] = [
    {
      key: 'hospedagem-sites',
      title: 'HOSPEDAGEM DE SITES',
      imgUrl: siteHosting,
      description:
        'Plano de hospedagem profissional, com serviço de email, SSL grátis, e WordPress Premium.',
      learnMoreLink: 'https://www.hostnet.com.br/hospedagem-de-sites/',
      buttonText: generatingKey
        ? 'Carregando...'
        : 'Contratar a partir de R$59,90',
      onClick: () => {
        goToAssine();
      },
    },
    {
      key: 'email-profissional',
      title: 'E-MAIL PROFISSIONAL',
      imgUrl: professionalEmail,
      description:
        'Ideal para empresas ou profissionais que desejam ter um endereço personalizado para se comunicar com clientes e fazer negócios.',
      learnMoreLink: 'https://www.hostnet.com.br/plano-email/',
      buttonText: generatingKey
        ? 'Carregando...'
        : 'Contratar a partir de R$29,90',
      onClick: () => {
        goToAssine();
      },
    },
    {
      key: 'registro-dominio',
      title: 'REGISTRO DE DOMÍNIO',
      imgUrl: domainRegistry,
      description:
        'Domínio é o endereço do seu site na Internet. Escolha um nome perfeito para registrar e ser localizado nos meios digitais.',
      learnMoreLink: 'https://www.hostnet.com.br/registro-de-dominio/',
      buttonText: 'Contratar a partir de R$59,90',
      onClick: () => {
        history.push('/registro/dominio-registrar');
      },
    },
    {
      key: 'site-pronto',
      title: 'SITE PRONTO',
      imgUrl: siteCreation,
      description:
        'Desenvolvemos o seu site com qualidade e rapidez. Usamos as melhores plataformas para entregar um site bonito e funcional.',
      learnMoreLink: 'https://www.hostnet.com.br/criacao-de-site/',
      buttonText: 'Falar com especialista',
      onClick: () => {
        openInNewTab('https://www.hostnet.com.br/criacao-de-site/');
      },
    },
    {
      key: 'loja-pronta',
      title: 'LOJA PRONTA',
      imgUrl: shopCreation,
      description:
        'Loja Virtual sob medida para o seu negócio, com visual profissional e tecnologias avançadas.',
      learnMoreLink: 'https://www.hostnet.com.br/criacao-de-loja-virtual/',
      buttonText: 'Falar com especialista',
      onClick: () => {
        openInNewTab('https://www.hostnet.com.br/criacao-de-loja-virtual/');
      },
    },
    {
      key: 'site-ead',
      title: 'CRIAÇÃO DE SITE EAD',
      imgUrl: eadCreation,
      description:
        'Site de Ensino a Distância, com a plataforma WordPress, para instituições e professores.',
      learnMoreLink: 'https://www.hostnet.com.br/criacao-de-site-ead/',
      buttonText: 'Falar com especialista',
      onClick: () => {
        openInNewTab('https://www.hostnet.com.br/criacao-de-site-ead/');
      },
    },
    {
      key: 'manutencao-site',
      title: 'MANUTENÇÃO DE SITE',
      imgUrl: siteMaintenance,
      description:
        'Serviço de manutenção de site para realizar edições relacionadas a conteúdo, imagens e atualizações de segurança.',
      learnMoreLink: 'https://www.hostnet.com.br/info/manutencao-de-sites/',
      buttonText: 'Contratar a partir de R$32,00',
      onClick: () => {
        history.push('/servicos-adicionais');
      },
    },
    {
      key: 'suporte-premium',
      title: 'SUPORTE PREMIUM',
      imgUrl: premiumSupport,
      description:
        'Serviço para atualizar o aplicativo do seu site, plugins e complementos do sistema, que visa o bom funcionamento e segurança do site.',
      learnMoreLink: 'https://www.hostnet.com.br/info/suporte-premium',
      buttonText: 'Contratar a partir de R$32,00',
      onClick: () => {
        history.push('/servicos-adicionais');
      },
    },
    {
      key: 'unidades-regionais',
      title: 'UNIDADES REGIONAIS',
      imgUrl: premiumSupport,
      description:
        'Encontre, na sua região, especialistas em criação de sites e lojas virtuais, marketing digital.',
      learnMoreLink: 'https://www.hostnet.com.br/seja-uma-franquia-hostnet/',
      buttonText: 'Falar com especialista',
      onClick: () => {
        openInNewTab('https://www.hostnet.com.br/unidades-regionais/');
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  let filteredCategories = categories;

  if (clientData.tipoContaInterno === 'CLIENTE DE FRANQUIA') {
    const categoriesToRemove = [
      'site-pronto',
      'loja-pronta',
      'site-ead',
      'manutencao-site',
      'suporte-premium',
      'unidades-regionais',
    ];

    filteredCategories = categories.filter(
      category => !categoriesToRemove.includes(category.key),
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:purchase.title')}
        description={t('titles:purchase.description')}
        icon={<FaPlus color="#ffffff" size={24} />}
      />
      <Row>
        {filteredCategories.map(category => (
          <Col key={category.key} sm={6} md={4} className="mb-4 d-flex">
            <Card className="text-center border-0">
              <Card.Body className="">
                <div className="img-container">
                  <img className="category-img" src={category.imgUrl} alt="" />
                </div>
                <Card.Title>
                  <div className="text-center my-3">
                    <CardTitle>{category.title}</CardTitle>
                  </div>
                </Card.Title>
                <Card.Text>{category.description}</Card.Text>
                <Card.Link target="_blank" href={category.learnMoreLink}>
                  Saiba mais
                </Card.Link>
              </Card.Body>
              <div className="btn-container">
                <PanelButton
                  className="w-100"
                  onClick={category.onClick}
                  disabled={generatingKey}
                >
                  {category.buttonText}
                </PanelButton>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Purchase;
