import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Formik } from 'formik';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';
import Loading from 'components/Loading';

import useRioDomainContacts from 'hooks/useRioDomainContacts';
import useRioDomainExtensions from 'hooks/useRioDomainsExtensions';
import useInternationalDomainExtensions from 'hooks/useInternationalDomainsExtensions';
import useInternationalDomainContacts from 'hooks/useInternationalDomainContacts';
import useBrDomainExtensions from 'hooks/useBrDomainsExtensions';
import useBrDomainContacts from 'hooks/useBrDomainsContacts';
import useBrDomainEntities from 'hooks/useBrDomainsEntities';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

interface DomainExtension {
  idExtensao: number;
  extensao: string;
}

interface NewDomainFormValues {
  domain: string;
  extension: string;
}

interface AvailableDomainResponse {
  data: {
    dominio: string;
    descricao: string;
    disponivel: boolean;
  };
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { contacts: rioContacts } = useRioDomainContacts();
  const { contacts: internationalContacts } = useInternationalDomainContacts();
  const { contacts: brContacts } = useBrDomainContacts();
  const { entities } = useBrDomainEntities();

  const {
    rioExtensions,
    isLoading: isLoadingRioExtensions,
  } = useRioDomainExtensions();
  const {
    internationalExtensions,
    isLoading: isLoadingInternationalExtensions,
  } = useInternationalDomainExtensions();
  const {
    brExtensions,
    isLoading: isLoadingBrExtensions,
  } = useBrDomainExtensions();

  const [loading, setLoading] = useState(false);
  const [extensions, setExtensions] = useState<DomainExtension[]>([]);

  const [availabilityDescription, setAvailabilityDescription] = useState('');

  const [showRioContactWarning, setShowRioContactWarning] = useState(false);
  const [showBrContactWarning, setShowBrContactWarning] = useState(false);
  const [showBrEntityWarning, setShowBrEntityWarning] = useState(false);
  const [
    showInternationalContactWarning,
    setShowInternationalContactWarning,
  ] = useState(false);

  useEffect(() => {
    setLoading(
      isLoadingInternationalExtensions ||
        isLoadingRioExtensions ||
        isLoadingBrExtensions,
    );
  }, [
    isLoadingInternationalExtensions,
    isLoadingRioExtensions,
    isLoadingBrExtensions,
  ]);

  useEffect(() => {
    const allExtensions = [
      ...rioExtensions,
      ...internationalExtensions,
      ...brExtensions,
    ];

    const preferredExtensions = [
      'com',
      'rio',
      'net',
      'org',
      'info',
      'biz',
      'cc',
      'tv',
      'ws',
      'bz',
      'com.br',
    ];

    const reorderedExtensions = allExtensions.sort((a, b) => {
      if (
        preferredExtensions.includes(a.extensao) &&
        preferredExtensions.includes(b.extensao)
      ) {
        return (
          preferredExtensions.indexOf(a.extensao) -
          preferredExtensions.indexOf(b.extensao)
        );
      }

      if (preferredExtensions.includes(a.extensao)) {
        return -1;
      }

      if (preferredExtensions.includes(b.extensao)) {
        return 1;
      }

      return 0;
    });

    setExtensions(reorderedExtensions);
  }, [brExtensions, internationalExtensions, rioExtensions]);

  async function checkAvailability(values: NewDomainFormValues) {
    try {
      const { domain, extension } = values;
      const domainName = `${domain}.${extension}`;

      setShowRioContactWarning(false);
      setShowInternationalContactWarning(false);

      setAvailabilityDescription('');

      if (extension === 'rio') {
        if (rioContacts.length === 0) {
          setShowRioContactWarning(true);
          return;
        }

        const response = await api.get<AvailableDomainResponse>(
          'rrpproxy/v1/disponibilidade',
          {
            params: {
              dominio: domainName,
            },
          },
        );

        if (response.data.data.disponivel) {
          history.push(
            `/registro/dominio-rio-registrar?dominio=${domain}&extensao=${extension}`,
          );
        } else {
          setAvailabilityDescription(response.data.data.descricao);
        }
      } else if (extension.endsWith('.br')) {
        if (brContacts.length === 0) {
          setShowBrContactWarning(true);
          return;
        }

        if (entities.length === 0) {
          setShowBrEntityWarning(true);
          return;
        }

        const response = await api.get<AvailableDomainResponse>(
          'registrobr/v1/dominio-disponibilidade',
          {
            params: {
              dominio: domainName,
            },
          },
        );

        if (response.data.data.disponivel) {
          history.push(
            `/registro/dominio-br-registrar?dominio=${domain}&extensao=${extension}`,
          );
        } else {
          setAvailabilityDescription(response.data.data.descricao);
        }
      } else {
        if (internationalContacts.length === 0) {
          setShowInternationalContactWarning(true);
          return;
        }
        const response = await api.get<AvailableDomainResponse>(
          'enom/v1/disponibilidade',
          {
            params: {
              dominio: domainName,
            },
          },
        );

        if (response.data.data.disponivel) {
          history.push(
            `/registro/dominio-internacional-registrar?dominio=${domain}&extensao=${extension}`,
          );
        } else {
          setAvailabilityDescription(response.data.data.descricao);
        }
      }
    } catch (err) {
      swal.fire(t('common:operationFailed'));
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:domainRegistryNew.title')}
          description={t('pages:domainRegistryNew.description')}
          directHelpLink="https://ajuda.hostnet.com.br/registro-de-dominio-na-hostnet/"
          helpContent={
            <div>
              <p>{t('pages:domainRegistryNew.helpContent.p1')}</p>
              <p>{t('pages:domainRegistryNew.helpContent.p2')}</p>
              <p>{t('pages:domainRegistryNew.helpContent.p3')}</p>
              <p>{t('pages:domainRegistryNew.helpContent.p4')}</p>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:domainRegistryNew.helpLinks.link1'),
              url: 'https://www.hostnet.com.br/info/primeiros-passos/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link2'),
              url:
                'https://www.hostnet.com.br/info/registro-de-dominio-gratis/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link3'),
              url:
                'https://www.hostnet.com.br/info/dicas-para-escolher-o-nome-do-dominio/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link4'),
              url:
                'https://www.hostnet.com.br/info/registro-de-dominios-internacionais/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link5'),
              url:
                'https://www.hostnet.com.br/info/registro-de-dominios-nacionais/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link6'),
              url: 'https://www.hostnet.com.br/info/registro-de-dominio-rio/',
            },
            {
              text: t('pages:domainRegistryNew.helpLinks.link7'),
              url:
                'https://www.hostnet.com.br/info/tutoriais-para-registro-de-dominio/',
            },
          ]}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            domain: '',
            extension: 'com',
          }}
          validationSchema={Yup.object().shape({
            domain: Yup.string()
              .required(t('validations:requiredField'))
              .matches(
                /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                t('pages:domainRegistryNew.validations.domainFormat'),
              ),
          })}
          onSubmit={checkAvailability}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:domainRegistryNew.formSubtitle')}
                />

                <Form.Group as={Row} controlId="domainRegistryNew.domain">
                  <Form.Label column sm={2} className="required">
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="domain"
                      value={props.values.domain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.domain && !!props.errors.domain
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.domain}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      <Trans>{t('pages:domainRegistryNew.domainTip')}</Trans>
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainRegistryNew.extension">
                  <Form.Label column sm={2}>
                    {t('common:extension')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="extension"
                      as="select"
                      value={props.values.extension}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.extension && !!props.errors.extension
                      }
                      disabled={props.isSubmitting}
                    >
                      {extensions.map(state => (
                        <option key={state.extensao} value={state.extensao}>
                          {state.extensao}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.extension}
                    </Form.Control.Feedback>

                    {showRioContactWarning && (
                      <div className="text-danger mt-2">
                        {t('pages:domainRegistryNew.noRioContacts')}
                        <Link to="/registro/contato-rio-adicionar?callback=domain-registration">
                          {t('common:clickHere').toLowerCase()}.
                        </Link>
                      </div>
                    )}

                    {showBrContactWarning && (
                      <div className="text-danger mt-2">
                        {t('pages:domainRegistryNew.noBrContacts')}
                        <Link to="/registro/contato-br-adicionar?callback=domain-registration">
                          {t('common:clickHere').toLowerCase()}.
                        </Link>
                      </div>
                    )}

                    {showBrEntityWarning && (
                      <div className="text-danger mt-2">
                        {t('pages:domainRegistryNew.noBrEntities')}
                        <Link to="/registro/entidade-br-adicionar?callback=domain-registration">
                          {t('common:clickHere').toLowerCase()}.
                        </Link>
                      </div>
                    )}

                    {showInternationalContactWarning && (
                      <div className="text-danger mt-2">
                        {t('pages:domainRegistryNew.noInternationalContacts')}
                        <Link to="/registro/contato-internacional-adicionar?callback=domain-registration">
                          {t('common:clickHere').toLowerCase()}.
                        </Link>
                      </div>
                    )}

                    <div className="text-danger mt-2">
                      {availabilityDescription}
                    </div>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:sending')
                    : t('pages:domainRegistryNew.checkAvailability')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
