import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaIdCard } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import Error from 'components/Error';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PhoneInput from 'components/PhoneInput';
import TableNoData from 'components/TableNoData';
import PanelButton from 'components/PanelButton';
import Alert from 'components/Alert';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';
import history from 'services/history';

import formatCurrency from 'utils/formatCurrency';

import { Container } from './styles';

import {
  allOptions,
  renunciationOptions,
  pricesOptions,
  customerServiceOptions,
  resourcesAndSystemsOptions,
  unavailabilityOptions,
  otherOptions,
} from './options';

interface HistoryResponse {
  data: {
    saldo: string;
  };
}

interface SitesBalanceResponse {
  data: {
    saldo: string;
  }[];
}

interface SiteBalance {
  idSite: number;
  site: string;
  balance: number;
  cancelable: boolean;
}

interface Complement {
  id: number;
  complement: string;
}

interface RequestCancelFormValues {
  name: string;
  phone: string;
  notes: string;
  sites: SiteBalance[];
}

const Cancellation: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canCancel, setCanCancel] = useState(true);

  const [reasonsIds, setReasonsIds] = useState<number[]>([]);
  const [complements, setComplements] = useState<Complement[]>([]);

  const [sitesBalance, setSitesBalance] = useState<SiteBalance[]>([]);

  const client = useSelector((state: StoreState) => state.client.info);
  const sites = useSelector((state: StoreState) => state.sites.list);
  const site = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    async function loadBalances() {
      try {
        setError(false);
        setLoading(true);

        const historyResponse = await api.get<HistoryResponse>(
          `financeiro/v1/cliente/${client.idCliente}/extrato`,
        );

        const balance = Number(historyResponse.data.data.saldo);

        if (client.tipoCobranca === 'UNICA' && balance < 0) {
          setCanCancel(false);
        }

        const sitesIds: number[] = [];

        sites.map(siteToLoad => sitesIds.push(siteToLoad.idSite));

        const sitesHistory = await api.get<SitesBalanceResponse>(
          `financeiro/v1/cliente/${client.idCliente}/saldo`,
          { params: { sites: sitesIds } },
        );

        const sitesBalanceFormatted = sites.map((loadedSite, index) => {
          const siteBalance = parseFloat(sitesHistory.data.data[index].saldo);

          const finalBalance =
            client.tipoCobranca === 'UNICA' ? balance : siteBalance;

          return {
            idSite: loadedSite.idSite,
            site: loadedSite.site,
            balance: finalBalance,
            cancelable: finalBalance >= 0,
          };
        });

        setSitesBalance(sitesBalanceFormatted);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadBalances();
  }, [client.idCliente, client.tipoCobranca, sites]);

  const handleChange = useCallback(
    (id: number, checked: boolean) => {
      const updatedReasonsIds = checked
        ? [...reasonsIds, id]
        : reasonsIds.filter(reasonId => reasonId !== id);

      setReasonsIds(updatedReasonsIds);
    },
    [reasonsIds],
  );

  const handleComplementChange = useCallback(
    (id, complementText) => {
      const filtered = complements.filter(complement => complement.id !== id);
      setComplements([...filtered, { id, complement: complementText }]);
    },
    [complements],
  );

  const getAllReasons = useCallback(() => {
    const reasons: string[] = [];

    reasonsIds.forEach(reasonId => {
      const reason = allOptions.find(option => option.id === reasonId);
      const complement = complements.find(option => option.id === reasonId);

      if (reason) {
        reasons.push(reason.text);
      }

      if (complement) {
        reasons.push(complement.complement);
      }
    });

    return reasons;
  }, [complements, reasonsIds]);

  const submitForm = useCallback(
    async (values: RequestCancelFormValues, formikHelpers) => {
      try {
        const reasons = getAllReasons();

        if (values.sites.length === 0) {
          swal.fire({
            icon: 'warning',
            html: t('pages:cancellation.selectSitesToCancel'),
          });

          return;
        }

        const sitesIds = values.sites.map(selectedSite => selectedSite.idSite);
        const phone = `55.${values.phone.replace(/[^0-9]/g, '')}`;

        await api.post('clientes/v1/cancelamento/solicitacao', {
          idCliente: client.idCliente,
          idSite: site.idSite,
          sites: sitesIds,
          nome: values.name,
          telefone: phone,
          motivos: reasons,
          observacoes: values.notes,
        });

        formikHelpers.resetForm();

        swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: t('pages:cancellation.success'),
        });

        history.push('/home');
      } catch (err) {
        swal.fire({
          title: t('pages:cancellation.failed'),
          html: err.response.data.error_description,
        });
      }
    },
    [client.idCliente, getAllReasons, site.idSite, t],
  );

  const sitesBalanceColumns = [
    {
      name: t('common:site'),
      selector: 'site',
    },
    {
      name: t('common:balance'),
      selector: 'balance',
      cell: (row: SiteBalance) => (
        <span className={row.balance < 0 ? 'negativo' : ''}>
          {row.balance < 0 ? formatCurrency(row.balance) : formatCurrency(0)}
        </span>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!canCancel) {
    return (
      <Container>
        <PageTitle
          title={t('pages:cancellation.title')}
          description={t('pages:cancellation.subTitle')}
          icon={<FaIdCard color="#FFFFFF" size={24} />}
        />
        <Alert variant="warning">
          {t('pages:cancellation.insufficientFunds')}
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:cancellation.title')}
        description={t('pages:cancellation.subTitle')}
        icon={<FaIdCard color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title="Dados do cancelamento"
          description={t('pages:cancellation.backupWarning')}
          directHelpLink="https://ajuda.hostnet.com.br/cancelamento-no-painel-de-controle/"
          helpContent={
            <div>
              <p>{t('pages:cancellation.helpContent.p1')}.</p>
              <p>{t('pages:cancellation.helpContent.p2')}.</p>
              <p>{t('pages:cancellation.helpContent.p3')}.</p>
              <p>{t('pages:cancellation.helpContent.p4')}.</p>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:cancellation.helpContent.a1'),
              url:
                'https://www.hostnet.com.br/info/copia-de-seguranca-dos-e-mails/',
            },
            {
              text: t('pages:cancellation.helpContent.a2'),
              url:
                'https://www.hostnet.com.br/info/backup-de-arquivos-via-filezilla/',
            },
            {
              text: t('pages:cancellation.helpContent.a3'),
              url:
                'https://www.hostnet.com.br/info/exportando-banco-de-dados-pelo-phpmyadmin/',
            },
          ]}
        />

        <Formik
          validateOnMount
          initialValues={{
            name: '',
            phone: '',
            notes: '',
            sites: [],
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body>
                <FormSubtitle
                  subTitle={t('pages:cancellation.contactDetails')}
                />

                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm={2}>
                    {t('common:label.name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="phone">
                  <Form.Label column sm={2}>
                    {t('common:label.whatsapp')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="negativo">
                      {t('pages:cancellation.important')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <div className="mt-5" />

                <FormSubtitle
                  subTitle={t('pages:cancellation.scopeOfCancellation')}
                />

                <DataTable
                  noHeader
                  selectableRows
                  selectableRowDisabled={row => !row.cancelable}
                  onSelectedRowsChange={row =>
                    props.setFieldValue('sites', row.selectedRows)
                  }
                  columns={sitesBalanceColumns}
                  data={sitesBalance}
                  noDataComponent={
                    <TableNoData text={t('pages:cancellation.noData')} />
                  }
                  className="datatables-table"
                />

                <div className="mt-5" />

                <FormSubtitle subTitle={t('pages:cancellation.renunciation')} />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {renunciationOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6">
                            <Form.Check
                              type="checkbox"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>

                <div className="mt-5" />

                <FormSubtitle
                  subTitle={t('pages:cancellation.customerService')}
                />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {customerServiceOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6">
                            <Form.Check
                              type="checkbox"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>

                <div className="mt-5" />

                <FormSubtitle subTitle={t('pages:cancellation.prices')} />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {pricesOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6" className="d-flex">
                            <Form.Check
                              type="checkbox"
                              className="align-self-center"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                          {option.input ? (
                            <Col sm="6">
                              <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Qual"
                                onChange={e =>
                                  handleComplementChange(
                                    option.id,
                                    e.target.value,
                                  )
                                }
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>

                <div className="mt-5" />

                <FormSubtitle
                  subTitle={t('pages:cancellation.resourcesAndSystems')}
                />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {resourcesAndSystemsOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6" className="d-flex">
                            <Form.Check
                              type="checkbox"
                              className="align-self-center"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                          {option.input ? (
                            <Col sm="6">
                              <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Qual"
                                onChange={e =>
                                  handleComplementChange(
                                    option.id,
                                    e.target.value,
                                  )
                                }
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>

                <div className="mt-5" />

                <FormSubtitle
                  subTitle={t('pages:cancellation.unavailability')}
                />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {unavailabilityOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6" className="d-flex">
                            <Form.Check
                              type="checkbox"
                              className="align-self-center"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>

                <div className="mt-5" />

                <FormSubtitle subTitle={t('pages:cancellation.others')} />
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    {otherOptions.map(option => (
                      <ListGroup.Item key={option.id}>
                        <Row>
                          <Col sm="6" className="d-flex">
                            <Form.Check
                              type="checkbox"
                              className="align-self-center"
                              id={option.id.toString()}
                              label={option.text}
                              onChange={e =>
                                handleChange(option.id, e.target.checked)
                              }
                            />
                          </Col>
                          {option.input ? (
                            <Col sm="6">
                              <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Qual"
                                onChange={e =>
                                  handleComplementChange(
                                    option.id,
                                    e.target.value,
                                  )
                                }
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card.Body>

              <div className="mt-5">
                <Card.Body>
                  <FormSubtitle subTitle={t('common:observations')} />
                  <Form.Group controlId="notes">
                    <Form.Control
                      name="notes"
                      as="textarea"
                      rows={4}
                      value={props.values.notes}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.notes && !!props.errors.notes}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid" />
                  </Form.Group>
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    variant="primary"
                    onClick={props.submitForm}
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {t('common:send')}
                  </PanelButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Cancellation;
