import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/Header';
import SiteSelector from 'components/SiteSelector';
import SideMenu from 'components/SideMenu';
import Overlay from 'components/Overlay';
import SiteSelectorButton from 'components/SiteSelectorButton';
import FinishPaymentButton from 'components/FinishPaymentButton';
import WhatsAppContactButton from 'components/WhatsAppContactButton';

import { StoreState } from 'store/createStore';

import { logout } from 'store/modules/auth/actions';

import { selectFavoriteSiteRequest } from 'store/modules/sites/actions';

import { siteRequest } from 'store/modules/site/actions';

import usePendingTickets from 'hooks/usePendingTickets';
import useAdditionalAccess from 'hooks/useAdditionalAccess';
import usePaymentSession from 'hooks/usePaymentSession';

import switchAccount from 'services/switchAccount';

import {
  Container,
  Content,
  ContentMargin,
  PageContent,
  SiteSelectorContainer,
} from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { site, plano, idSite: selectedSiteId } = useSelector(
    (state: StoreState) => state.site.info,
  );

  const { list: sites, idFavoriteSite, updating: updatingSites } = useSelector(
    (state: StoreState) => state.sites,
  );

  const { email1, contaComSite, tipoCobranca } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { loading: loadingSite } = useSelector(
    (state: StoreState) => state.site,
  );

  const { list: options } = useSelector((state: StoreState) => state.options);

  const isMobile = window.innerWidth <= 991;

  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(isMobile);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [siteSelectorIsOpen, setSiteSelectorIsOpen] = useState(false);

  const { pendingTickets } = usePendingTickets();
  const { additionalAccess } = useAdditionalAccess();
  const { paymentSession } = usePaymentSession(selectedSiteId, tipoCobranca);

  const hasAdditionalAccess = additionalAccess.length > 0;
  const accountWithSitesToAccess = contaComSite || hasAdditionalAccess;

  let siteName = site;
  let planName = plano;

  if (!contaComSite && hasAdditionalAccess) {
    siteName = 'Nenhum site selecionado';
    planName = 'Selecionar site';
  }
  return (
    <Container>
      <Header
        site={siteName}
        plan={planName}
        email={email1}
        loadingSite={loadingSite}
        sideMenuCollapsed={sideMenuCollapsed}
        mobileMenuIsOpen={mobileMenuIsOpen}
        accountWithSites={accountWithSitesToAccess}
        paymentSession={paymentSession}
        logout={() => dispatch(logout())}
        handleSiteSelector={() => setSiteSelectorIsOpen(!siteSelectorIsOpen)}
        handleSideMenu={() => setSideMenuCollapsed(!sideMenuCollapsed)}
        handleMobileMenu={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
      />

      <Overlay
        active={!sideMenuCollapsed}
        onClick={() => setSideMenuCollapsed(true)}
      />

      <SiteSelector
        sites={sites}
        additionalAccess={additionalAccess}
        loading={loadingSite}
        updating={updatingSites}
        open={siteSelectorIsOpen}
        idFavoriteSite={idFavoriteSite}
        selectSite={(idSite: number) => dispatch(siteRequest(idSite))}
        selectAdditionalSite={async (key: string) => switchAccount(key)}
        handleSiteSelector={() => setSiteSelectorIsOpen(false)}
        handleFavoriteSite={idSite =>
          dispatch(selectFavoriteSiteRequest(idSite))
        }
      />

      <Content>
        <ContentMargin>
          <SideMenu
            collapsed={sideMenuCollapsed}
            pendingTickets={pendingTickets}
            siteOptions={options}
            loadingSite={loadingSite}
          />
          <PageContent className={sideMenuCollapsed ? 'closed' : ''}>
            {contaComSite && (
              <SiteSelectorContainer className="border-bottom d-lg-none d-block d-sm-flex">
                <SiteSelectorButton
                  site={site}
                  loading={loadingSite}
                  plan={plano}
                  onClick={() => setSiteSelectorIsOpen(!siteSelectorIsOpen)}
                />
                <div className="pr-4 pt-2 pt-sm-0">
                  {paymentSession.active && (
                    <FinishPaymentButton sessionKey={paymentSession.key} />
                  )}
                </div>
              </SiteSelectorContainer>
            )}
            {children}
          </PageContent>
        </ContentMargin>
      </Content>
      <WhatsAppContactButton />
    </Container>
  );
};

export default DefaultLayout;
