import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import useCountryStates from 'hooks/useCountryStates';
import useBrDomainContacts from 'hooks/useBrDomainsContacts';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import PhoneInput from 'components/PhoneInput';
import Error from 'components/Error';
import Loading from 'components/Loading';

import toast from 'services/toast';
import hashIds from 'services/hashIds';
import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type BrContactEditProps = RouteComponentProps<MatchParams>;

interface EditContactFormValues {
  name: string;
  number: string;
  complement: string;
  email: string;
  address: string;
  zipCode: string;
  city: string;
  state: string;
  countryCode: string;
  phone: string;
  extensionNumber: string;
}

const Edit: React.FC<BrContactEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idContact] = hashIds.decode(match.params.id);

  const { countryStates, isLoading } = useCountryStates();

  const { contacts, refetch: refetchContacts, fetching } = useBrDomainContacts(
    Number(idContact),
  );

  const [error, setError] = useState(false);
  const [contact, setContact] = useState<typeof contacts[number]>();

  useEffect(() => {
    const findContact = contacts.find(
      searchContact => searchContact.id === idContact,
    );

    if (!findContact) {
      setError(true);
      return;
    }

    setError(false);
    setContact(findContact);
  }, [contacts, idContact]);

  async function editContact(values: EditContactFormValues) {
    try {
      await api.put(`registrobr/v1/contato`, {
        idContato: idContact,
        cidade: values.city,
        email: values.email,
        endereco: values.address,
        numero: values.number,
        complemento: values.complement,
        estado: values.state,
        cep: values.zipCode.replace(/\D/g, ''),
        telefone: `${values.countryCode}.${values.phone.replace(/\D/g, '')}`,
        ramal: values.extensionNumber,
      });

      toast.fire(t('pages:brDomainContactEdit.success'));

      refetchContacts();

      history.push(`/registro/contatos`);
    } catch (err) {
      swal.fire({
        title: t('pages:brDomainContactEdit.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (fetching || isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:brDomainContactEdit.title')}
          description={t('pages:brDomainContactEdit.description')}
        />

        <Formik
          enableReinitialize
          initialValues={{
            contact: contact?.contact,
            name: contact?.name || '',
            number: contact?.number || '',
            complement: contact?.complement || '',
            email: contact?.email || '',
            address: contact?.address || '',
            zipCode: contact?.zipCode || '',
            city: contact?.city || '',
            state: contact?.state || '',
            countryCode: '55',
            phone: contact?.phone || '',
            extensionNumber: contact?.extensionNumber || '',
          }}
          validationSchema={Yup.object().shape({
            number: Yup.string().required(t('validations:requiredField')),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            address: Yup.string().required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            city: Yup.string().required(t('validations:requiredField')),
            state: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={editContact}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:brDomainContactEdit.contactDetails')}
                />

                <Form.Group as={Row} controlId="contact.contact">
                  <Form.Label column sm={2}>
                    {t('common:contact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="contact"
                      value={props.values.contact}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.name">
                  <Form.Label column sm={2}>
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="name"
                      value={props.values.name}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:brDomainContactEdit.emailTip')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.number">
                  <Form.Label column sm={2} className="required">
                    {t('common:number')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="number"
                      value={props.values.number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.number && !!props.errors.number
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.complement">
                  <Form.Label column sm={2}>
                    {t('common:complement')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="complement"
                      value={props.values.complement}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.complement && !!props.errors.complement
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.complement}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputMask
                      name="zipCode"
                      mask="99999-999"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.zipCode}
                      disabled={props.isSubmitting}
                      className={`form-control ${
                        !!props.touched.zipCode &&
                        !!props.errors.zipCode &&
                        'is-invalid'
                      }`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.city">
                  <Form.Label column sm={2} className="required">
                    {t('common:city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.state">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="state"
                      as="select"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    >
                      {countryStates.map(state => (
                        <option key={state.id} value={state.uf}>
                          {state.uf}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.phone">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.extensionNumber">
                  <Form.Label column sm={2}>
                    {t('common:extensionNumber')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="extensionNumber"
                      value={props.values.extensionNumber}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.extensionNumber &&
                        !!props.errors.extensionNumber
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.extensionNumber}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/contatos"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
