import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #25d366;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background 0.2s;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &:hover {
      background: #28a745;
    }
  }
`;
