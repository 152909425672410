import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const Container = styled.div``;

export const Content = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;

export const ContentMargin = styled.div`
  width: 100%;
  margin-top: 73px;
`;

export const PageContent = styled.main`
  transition: all 0.3s ease-in-out;

  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 40px;

  &.closed {
    margin-left: 0;
  }

  @media only screen and (min-width: 991px) {
    margin-left: 250px;
  }

  @media only screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const SiteSelectorContainer = styled.div`
  display: flex;
  flex-flow: row-reverse;

  padding: 16px 0px;
`;
