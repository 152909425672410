import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import { Container } from './styles';

const WhatsAppContactButton: React.FC = () => {
  return (
    <Container>
      <a
        href="https://wa.me/552120186703"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={24} />
        {/* <img src="/images/whatsapp.svg" alt="WhatsApp" /> */}
        <span className="ml-2">POSSO AJUDAR?</span>
      </a>
    </Container>
  );
};

export default WhatsAppContactButton;
