import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import {
  FaCloud,
  FaEnvelope,
  FaExchangeAlt,
  FaHdd,
  FaMemory,
  FaRandom,
  FaServer,
} from 'react-icons/fa';

import { StoreState } from 'store/createStore';

import useUltramail from 'hooks/useUltramail';

import SiteResource from 'components/SiteResource';
import SiteResourceUsage from 'components/SiteResourceUsage';
import PanelCard from 'components/PanelCard';

const SiteResources: React.FC = () => {
  const { t } = useTranslation();

  const { info, resources } = useSelector((state: StoreState) => state.site);

  const { ultramail } = useUltramail(info.idSite);

  return (
    <div>
      <div className="my-4">
        <Row>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name={t('common:plan')}
              value={info.plano}
              color="blue"
              icon={<FaCloud size="22" />}
            />
          </div>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name="CPU"
              value={`${resources.cpuTotal}v`}
              color="violet"
              icon={<FaServer size="18" />}
            />
          </div>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name={t('common:memory')}
              value={`${parseFloat(resources.memoriaTotal)}GB`}
              color="green"
              icon={<FaMemory size="24" />}
            />
          </div>
        </Row>
      </div>
      <PanelCard>
        <Card.Body>
          <Row>
            <Col xl={3} md={6} className="border-md-right pb-4 pb-xl-0 px-4">
              <SiteResourceUsage
                total={`${resources.espacoDiscoTotalGB}GB`}
                used={`${resources.espacoDiscoUtilizadoGB}GB`}
                percentage={resources.espacoDiscoPorcentualUtilizado}
                color="blue"
                icon={<FaHdd />}
                label={t('common:disk')}
              />
            </Col>
            <Col xl={3} md={6} className="border-xl-right pb-4 pb-xl-0 px-4">
              <SiteResourceUsage
                total={
                  resources.planoIlimitado
                    ? t('common:unlimited')
                    : `${resources.trafegoTotalGB}GB`
                }
                used={`${resources.trafegoUtilizadoGB}GB`}
                percentage={resources.trafegoPorcentualUtilizado}
                color="violet"
                icon={<FaExchangeAlt />}
                label={t('common:transfer')}
              />
            </Col>
            <Col xl={3} md={6} className="border-md-right pb-4 pb-md-0 px-4">
              <SiteResourceUsage
                total={`${resources.siteQtd}`}
                used={`${resources.sitesCriados}`}
                percentage={resources.sitesPorcentualUtilizado}
                color="yellow"
                icon={<FaRandom />}
                label={t('common:site_plural')}
              />
            </Col>
            <Col xl={3} md={6} className="px-4 mb-sm-2">
              <SiteResourceUsage
                total={`${ultramail.totalGB}GB`}
                used={`${ultramail.utilizadoGB}GB`}
                percentage={ultramail.percentualUtilizado}
                color="green"
                icon={<FaEnvelope />}
                label={t('common:email')}
              />
            </Col>
          </Row>
        </Card.Body>
      </PanelCard>
    </div>
  );
};

export default SiteResources;
